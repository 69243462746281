import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { BsArrow90DegLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

function OldWorkout() {
  const { user, getAccessTokenSilently } = useAuth0();

  const { workoutId } = useParams();
  const [workout, setWorkout] = useState(null);

  useEffect(() => {
    const fetchWorkout = async () => {
      const token = await getAccessTokenSilently({
        audience: 'https://workout-api.herrick.io'
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/workouthistory/item?email=${user.email}&id=${workoutId}`, { headers: {
            Authorization: `Bearer ${token}`
          }}
        );
        const data = await response.json();
        setWorkout(data);
      } catch (error) {
        console.error("Error fetching workout data:", error);
      }
    };

    fetchWorkout();
  }, [workoutId, user.email, getAccessTokenSilently]);

  if (workout === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 grid-rows-2 gap-4">
          <div className="flex items-center justify-center">
            <div className="overflow-x-auto">
              <article className="prose text-center">
                <h1>
                  <Link to="/workoutlogs">
                    <BsArrow90DegLeft />
                  </Link>
                  Old Workout
                </h1>
                <h2>
                  {new Date(workout.date).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}{" "}
                  - Day {workout.day}, Week {workout.week}, Cycle{" "}
                  {workout.cycle}
                </h2>
                {workout.exercises && (
                  <table className="table table-zebra">
                    <thead>
                      <tr>
                        <th>Exercise Name</th>
                        <th>Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workout.exercises.map((exercise) => (
                        <tr key={exercise._id}>
                          <td>{exercise.name}</td>
                          <td>{exercise.weight}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OldWorkout;
