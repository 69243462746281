import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Workout from "./pages/Workout";
import Cycle from "./pages/Cycle";
import Exercises from "./pages/Exercises";
import WorkoutLogs from "./pages/WorkoutLogs";
import OldWorkout from "./pages/OldWorkout";

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {isAuthenticated ? (
        <div>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Workout />} />
              <Route path="/cycle" element={<Cycle />} />
              <Route path="/exercises" element={<Exercises />} />
              <Route path="/workoutlogs" element={<WorkoutLogs />} />
              <Route
                path="/oldworkout/:workoutId"
                element={<OldWorkout />}
              />{" "}
            </Routes>
          </Router>
        </div>
      ) : (
        <div>Redirecting to login...</div>
      )}
    </>
  );
}

export default App;
