import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Cycle() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [cycleData, setCycleData] = useState(null);
  const [day, setDay] = useState("");
  const [week, setWeek] = useState("");
  const [cycle, setCycle] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchCycleData() {
      try {
        const token = await getAccessTokenSilently({
          audience: 'https://workout-api.herrick.io'
        });
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cycle?email=${user.email}`, { headers: {
            Authorization: `Bearer ${token}`
          }}
        );
        const data = await response.json();
        setCycleData(data);
        setDay(data.day ? String(data.day) : "");
        setWeek(data.week ? String(data.week) : "");
        setCycle(data.cycle ? String(data.cycle) : "");
      } catch (error) {
        console.error("Error fetching workout data:", error);
      }
    }

    fetchCycleData();
  }, [user.email, getAccessTokenSilently]);

  const handleUpdate = async () => {
    const token = await getAccessTokenSilently({
      audience: 'https://workout-api.herrick.io'
    });
    const payload = {
      day: day.trim() === "" ? 0 : parseInt(day, 10),
      week: week.trim() === "" ? 0 : parseInt(week, 10),
      cycle: cycle.trim() === "" ? 0 : parseInt(cycle, 10),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cycle?email=${user.email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setIsError(true);
        setMessage(`${errorData.error}`);
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setIsError(false);
      setMessage("Updated!");
      console.log("Update successful:", responseData);
    } catch (error) {
      console.error("Error updating cycle data:", error);
    }
  };

  if (!cycleData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 grid-rows-2 gap-4">
          <div className="flex items-center justify-center">
            <div className="overflow-x-auto">
              <article className="prose text-center">
                <h1>Edit Cycle</h1>
                <label className="input input-primary input-bordered flex items-center gap-2">
                  <b>Day</b>
                  <input
                    type="text"
                    className="grow"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  />
                </label>
                <br />
                <label className="input input-primary input-bordered flex items-center gap-2">
                  <b>Week</b>
                  <input
                    type="text"
                    className="grow"
                    value={week}
                    onChange={(e) => setWeek(e.target.value)}
                  />
                </label>
                <br />
                <label className="input input-primary input-bordered flex items-center gap-2">
                  <b>Cycle</b>
                  <input
                    type="text"
                    className="grow"
                    value={cycle}
                    onChange={(e) => setCycle(e.target.value)}
                  />
                </label>
                <br />
                <button
                  className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-lg"
                  onClick={handleUpdate}
                >
                  Update
                </button>
                {message && (
                  <div className={isError ? "text-red-500" : "text-green-500"}>
                    {message}
                  </div>
                )}
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cycle;
