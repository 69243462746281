import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Workout() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [workoutData, setWorkoutData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    async function fetchWorkoutData() {
      const token = await getAccessTokenSilently({
        audience: 'https://workout-api.herrick.io'
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/workout?email=${user.email}`, { headers: {
            Authorization: `Bearer ${token}`
          }}
        );
        const data = await response.json();
        setWorkoutData(data);


      } catch (error) {
        console.error("Error fetching workout data:", error);
      }
    }

    fetchWorkoutData();
  }, [user.email, getAccessTokenSilently]);

  const handleCompleteWorkout = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: 'https://workout-api.herrick.io'
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/workout/complete?email=${user.email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Great Work!");
      } else {
        console.error("Error completing workout:", response.statusText);
      }
    } catch (error) {
      console.error("Error completing workout:", error);
    }
  };

  if (!workoutData) {
    return <div>Loading...</div>;
  }

  const { cycle, exercises } = workoutData;

  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 grid-rows-2 gap-4">
          <div className="flex items-center justify-center">
            <div className="overflow-x-auto">
              <article className="prose text-center">
                <h1>{`Day ${cycle.day}, Week ${cycle.week}, Cycle ${cycle.cycle}`}</h1>
                <table className="table table-zebra">
                  <thead>
                    <tr>
                      <th>Exercise</th>
                      <th>Weight</th>
                      <th>Reps</th>
                      <th>Warm-Up #1</th>
                      <th>Warm-Up #2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exercises.map((exercise) => (
                      <tr key={exercise.order}>
                        <td>{exercise.name}</td>
                        <td>{exercise.weight}</td>
                        <td>{exercise.reps}</td>
                        <td>{exercise["warm-up-one"]}</td>
                        <td>{exercise["warm-up-two"]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-lg"
                  onClick={handleCompleteWorkout}
                >
                  Complete Workout
                </button>
                {successMessage && <p>{successMessage}</p>}
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Workout;
