import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

function WorkoutLogs() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [workoutLogs, setWorkoutLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently({
        audience: 'https://workout-api.herrick.io'
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/workouthistory?email=${user.email}`, { headers: {
            Authorization: `Bearer ${token}`
          }}
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedLogs = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setWorkoutLogs(sortedLogs);
      } catch (error) {
        console.error("Error fetching workout logs:", error);
      }
    };

    fetchData();
  }, [user.email, getAccessTokenSilently]);

  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 grid-rows-2 gap-4">
          <div className="flex items-center justify-center">
            <div className="overflow-x-auto">
              <article className="prose text-center">
                <h1>Workout Logs</h1>
                <table className="table table-zebra">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Week</th>
                      <th>Cycle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workoutLogs.map((log) => (
                      <tr key={log._id}>
                        <td>
                          <div>
                            <Link to={"/oldworkout/" + log._id}>
                              {new Date(log.date).toLocaleString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div>{log.day}</div>
                        </td>
                        <td>
                          <div>{log.week}</div>
                        </td>
                        <td>
                          <div>{log.cycle}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkoutLogs;
