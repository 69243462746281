import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";

const { domain, clientId } = config;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={process.env.REACT_APP_REDIRECT_URI}
    authorizationParams={{
    audience: "https://workout-api.herrick.io",
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
