import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Exercises() {
  const { user, getAccessTokenSilently } = useAuth0();

  const [exercisesData, setExercisesData] = useState(null);
  const [updatedExercises, setUpdatedExercises] = useState([]);
  const [updateMessage, setUpdateMessage] = useState("");

  useEffect(() => {
    async function fetchExercisesData() {
      try {
        const token = await getAccessTokenSilently({
          audience: 'https://workout-api.herrick.io'
        });
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/exercises?email=${user.email}`, { headers: {
            Authorization: `Bearer ${token}`
          }}
        );
        const data = await response.json();
        setExercisesData(data);
        setUpdatedExercises(data.map((exercise) => ({ ...exercise })));
      } catch (error) {
        console.error("Error fetching workout data:", error);
      }
    }

    fetchExercisesData();
  }, [user.email, getAccessTokenSilently]);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setUpdatedExercises((prevExercises) =>
      prevExercises.map((exercise, i) =>
        i === index ? { ...exercise, weight: value } : exercise
      )
    );
  };

  const handleUpdate = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: 'https://workout-api.herrick.io'
      });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/exercises?email=${user.email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ exercises: updatedExercises }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Update successful:", responseData);
      setUpdateMessage("Updated!");
    } catch (error) {
      console.error("Error updating workout data:", error);
      setUpdateMessage("Error updating data.");
    }
  };

  if (!exercisesData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 grid-rows-2 gap-4">
        <div className="flex items-center justify-center">
          <div className="overflow-x-auto">
            <article className="prose text-center">
              <h1>Edit Weights</h1>
              {updatedExercises.map((exercise, index) => (
                <div key={exercise.name}>
                  <label className="input input-primary input-bordered flex items-center gap-2">
                    <b>{exercise.name}</b>
                    <input
                      type="text"
                      className="grow"
                      value={exercise.weight}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </label>
                  <br />
                </div>
              ))}
              <button
                className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-lg"
                onClick={handleUpdate}
              >
                Update
              </button>
              {updateMessage && (
                <div className="mt-2 text-green-500">{updateMessage}</div>
              )}
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exercises;
